import React, { useEffect, useState } from "react";
import "./styles.css";
import logoFoorter from "../../assets/svg/logo_white.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import dayjs from "dayjs";
//import { CreateEventButton } from "../CreateEventButton";
//import { useTranslation } from "react-i18next";
//import DownloadAppFooter from "../DownloadAppFooter";
//import { Container, Row, Col } from "react-bootstrap";

/* const facebook = process.env.REACT_APP_FACEBOOK;
const instagram = process.env.REACT_APP_INSTAGRAM;
const linkedin = process.env.REACT_APP_LINKEDIN; 
const linkedin = process.env.REACT_APP_LINKEDIN;
const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);*/

const MOBILE_WIDTH = 768;

function Footer() {
  const location = useLocation();
  //const { t } = useTranslation();
  const [inCheckout, setInCheckout] = useState(false);
  const [, setIsTablet] = useState(false);

  useEffect(() => {
    setInCheckout(window.location.pathname.match(/\/checkout\/.*/)?.length);

    const checkIsTablet = () => {
      const width = window.innerWidth;
      setIsTablet(
        (width >= MOBILE_WIDTH && width <= 1024) || width < MOBILE_WIDTH
      );
    };

    checkIsTablet();
    window.addEventListener("resize", checkIsTablet);

    return () => {
      window.removeEventListener("resize", checkIsTablet);
    };
  }, [location]);

  function pageFaqs() {
    localStorage.removeItem("TIPE_FAQ");
    window.location.href = "/faqs";
  }

  return (
    <>
      <div id="footer" className={inCheckout ? "checkout-footer" : ""}>
        <div className="row">
          <div className="col-sm-12 col-md-12 text-center">
            <img id="footer-logo" alt="" src={logoFoorter} />
          </div>
          <div className="col-sm-12 col-md-12 text-center">
            <div
              className="fale-click"
              onClick={() => pageFaqs("Ingresso-Produtor")}
            >
              Atendimento ao Cliente
            </div>
          </div>
        </div>
        <hr />
        <div
          id="copyright"
          className="text-center justify-content-center col-md-12"
        >
          <p>Virtual Ticket © {dayjs().format("YYYY")}</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
