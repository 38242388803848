import React from "react";
import DateHelper from "../../Helpers/date";
import ImageHandlerService from "../../services/image-handler";
import bannerDefault from "../../assets/img/bannerDefault.png";
import bannerBG from "../../assets/img/banner-bg.png";
import FavoriteButton from "../FavoriteButton";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./index.css";
import { isInZigApp } from "../../services/utils";

function Banner({ event, isCarousel, onPlaceClick }) {
  const history = useHistory();
  const { t } = useTranslation();
  //const word = t("CommonExpressions.to");

  const bannerUrl =
    !event.banner || event.banner.includes("/bannerDefault")
      ? bannerDefault
      : ImageHandlerService.getImageFromUrl(event.banner, {
          format: "webp",
          resize: { height: 350, fit: "contain" },
        });

  const showEventHour = process.env.REACT_APP_SHOW_EVENT_HOUR === "true";

  return (
    <>
      <div
        id="main-banner"
        className={`d-flex align-items-center position-relative ${
          isInZigApp() ? "main-banner-zig" : ""
        }`}
      >
        <div
          id="banner-background"
          style={{ backgroundImage: `url(${bannerBG})` }}
        ></div>
        <div
          id="banner-content"
          className={`container banner-short ${
            isInZigApp() ? "banner-short-zig" : ""
          }`}
        >
          <div
            id="banner-img"
            className="banner-img-short"
            style={{
              backgroundImage: `url(${bannerUrl})`,
              cursor: `${isCarousel ? "pointer" : "auto"}`,
            }}
            onClick={() => {
              if (isCarousel) {
                history.push(`/eventos/${event.slug}`);
              }
            }}
          ></div>

          <div className="d-flex col-sm-12 col-lg-4 banner-infos">
            <div className="name-date d-flex flex-column">
              <h2>{event.name}</h2>
              <span className="banner-date">
                {" "}
                <i className="far fa-calendar-check"></i>{" "}
                {DateHelper.format(
                  event.start_date,
                  t("CommonExpressions.dateFormat")
                )}
              </span>
            </div>

            <div className="interaction d-flex flex-row">
              <FavoriteButton
                eventId={event.id}
                eventName={event.name}
                style={{
                  fontSize: "1.8rem",
                  height: "3rem",
                  width: "3rem",
                }}
              />
            </div>

            {/* {event.locations.map((location) => ( */}
            {/* <div> key={location.id}> */}
            <div className="d-flex flex-column">
              <span className="banner-place" id="place-ellips-none">
                {event.is_online === 1
                  ? "Online"
                  : (event.event_location?.name
                      ? `${event.event_location.name} - `
                      : "") + event.event_location.formatted_address}
              </span>

              <span className="banner-place">
                {DateHelper.format(event.start_date, "HH:mm")}

                {showEventHour && (
                  <>às {DateHelper.format(event.end_date, "HH:mm")}</>
                )}
              </span>

              {!isCarousel ? (
              event.age_range ? (
                <span className="banner-place ">
                  Classificação {event.age_range} anos{" "}
                </span>
              ) : (
                <span className="banner-place ">Classificação: Livre </span>
              )) : null}
            </div>
            {isCarousel && (
              <NavLink className="nav-link-btn" to={`/eventos/${event.slug}`}>
                VER DETALHES
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
