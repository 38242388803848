import React from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";

const index = ({ title, text, style }) => (
  <div id="loading" style={style}>
    <div id="loading-symbol">
      <div id="loading-border"></div>
      <FontAwesomeIcon icon={faTicketAlt} id="loading-icon" />
      {/* <div id="loading-icon">
        <span></span>
      </div> */}
    </div>
    <p>{title}</p>
    <p>{text}</p>
  </div>
);

export default index;
