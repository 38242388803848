/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Login from "../Login";
import LoginMethods from "../LoginMethods";
import Register from "../Register";

function AuthenticationFlow({
  show,
  showSignUp,
  nextToUrl,
  onClose,
  onLoginCallback,
}) {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openSignMethods, setOpenSignMethods] = useState(false);

  const enableSSO = false

  const handleCloseSignIn = (showSignUp = false, isSucceded = false) => {
    setOpenSignIn(false);
    setOpenSignUp(showSignUp);

    if (enableSSO && !isSucceded) {
      setOpenSignMethods(!showSignUp);
    }

    if (!enableSSO || isSucceded) {
      onClose();
    }
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
    setOpenSignIn(true);
  };

  const handleCloseSignMethods = () => {
    setOpenSignMethods(false);
    onClose();
  };

  const handleShowSignIn = () => {
    setOpenSignIn(true);
    setOpenSignUp(false);
    setOpenSignMethods(false);
  };

  useEffect(() => {
    if (show) {
      if (showSignUp) {
        setOpenSignUp(true);
      } else {
        if (enableSSO) {
          setOpenSignMethods(true);
        } else {
          handleShowSignIn();
        }
      }
    }
  }, [show]);

  return (
    <>
      <LoginMethods
        show={openSignMethods}
        nextToUrl={nextToUrl}
        onClose={handleCloseSignMethods}
        onClickEmail={handleShowSignIn}
      />

      <Login
        show={openSignIn}
        onClose={(isSucceded) => handleCloseSignIn(false, isSucceded)}
        onClickSignUp={() => handleCloseSignIn(true)}
        onLoginCallback={onLoginCallback}
      />

      <Register
        show={openSignUp}
        className="btn btn-primary btn-sm btn-login"
        closeRegister={handleCloseSignUp}
      />
    </>
  );
}

export default AuthenticationFlow;
