import React from "react";
//import { Link } from "react-router-dom";
import thumbnailDefault from "../../assets/img/producer-thumbnail.jpg";
import ImageHandlerService from "../../services/image-handler";

import "./index.css";
//import { isInZigApp } from "../../services/utils";
//import MoengageService from "../../services/moengage";
//import { useTranslation } from "react-i18next";

export default function Index({ event, location, user }) {
  const producerImage = ImageHandlerService.getImageFromUrl(
    event.producer.thumbnail,
    {
      format: "webp",
      resize: { height: 120, fit: "contain" },
    },
    thumbnailDefault
  );
  //const { t } = useTranslation();

/*   const locationButtonHandleClick = async () => {
    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "View map",
            attributes: {
              event_id: event.id,
              organization: event.producer.name,
              location_address: location?.name || location?.formatted_address,
            },
          },
        ],
        platform: "web",
      });
    }
  }; */

  return (
    <div className="background-produtores">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="productor ">
              <div
                className="productor-img"
                style={{ backgroundImage: `url(${producerImage})` }}
              ></div>

              <div className="details">
                <ul className="">
                  <li>Produzido por: </li>
                  <li>
                    <h4>{event.producer.name}</h4>
                  </li>
                  <li>
                    <a
                      href={`/${
                        event.producer.type === "organization"
                          ? "organizacoes"
                          : "produtores"
                      }/${event.producer.slug}`}
                      className="product-links"
                    >
                      Mais eventos
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
